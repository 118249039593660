import { Article as ArticleDTS, ItemList } from 'schema-dts'

import { ArticleRecipesBlockProps } from '../../components/molecules/ArticleRecipesBlock'
import { app } from '../../configuration'
import { Article, ArticleUnion } from '../../graphql/generated/api-graphql'
import { escapeHtmlForJsonLd } from '../../helpers/StringHelpers'
import Router, { routes } from '../../routes/Router'
import { ArticleCardProps } from '../../components/molecules/ArticleCard'
import { resolveArticleRoute } from '../article/resolveArticleRoute'

export const jsonLdArticle = (props: ArticleUnion): ArticleDTS => {
  return {
    '@type': 'Article',
    headline: props.title,
    ...(props?.publicationDate && {
      datePublished: new Date(props.publicationDate).toISOString(),
    }),
    ...(props?.updatedAt && {
      dateModified: new Date(props.updatedAt).toISOString(),
    }),
    ...(props.mainImage?.downloadUrl && {
      image: props.mainImage?.downloadUrl,
    }),
    author: {
      '@type': 'Organization',
      name: props.brand?.name,
      ...(props.brand?.slug && {
        url:
          app.APP_URL +
          Router.getRouteUrl(routes.brand, { slug: props.brand?.slug }),
      }),
    },
    publisher: {
      '@type': 'Organization',
      name: props.brand?.name,
    },
    description: escapeHtmlForJsonLd(props.description ?? ''),
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id':
        app.APP_URL +
        Router.getRouteUrl(
          resolveArticleRoute({
            __typename: props.__typename,
            type: (props as Article)?.type,
          }),
          { slug: props.slug }
        ),
    },
  }
}

export const jsonLdArticleList = (
  title: string,
  articles: ArticleRecipesBlockProps
): ItemList => {
  return {
    '@type': 'ItemList',
    name: title,
    numberOfItems: articles?.recipes?.length,
    itemListElement: articles?.recipes?.map((recipe, index) => ({
      '@type': 'ListItem',
      position: index,
      url: app.APP_URL + recipe?.buttons?.[0]?.href,
      image: recipe?.pictureProps?.images?.[0]?.src || '',
      name: recipe?.title,
      description: escapeHtmlForJsonLd(recipe?.htmlProps?.text ?? ''),
    })),
  }
}

export const jsonLdListArticleBlock = (
  title: string,
  articles: ArticleCardProps[]
): ItemList => {
  return {
    '@type': 'ItemList',
    name: title,
    numberOfItems: articles?.length,
    itemListElement: articles?.map((article, index) => ({
      '@type': 'ListItem',
      position: index,
      url: app.APP_URL + article?.linkProps?.href,
      image: article.imageProps?.images?.[0]?.src || '',
      name: article.title,
    })),
  }
}
